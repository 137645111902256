<template>
    <div class="done">
        <div class="done-top">
            <img src="@/assets/new_img/img12.png" alt="" srcset="">
        </div>
        <div class="center">
            <div class="p1">全国只有约0.7%的研究生学历</div>
            <div class="p2">“你将是他们中的一员”</div>
            <div class="p3">已获得1000进阶币<van-icon name="arrow" /></div>
        </div>

        <div v-if="status">
            <div class="list1" @click="eventList({lunshu:1,nandu:0})">
                <div class="left">
                    <div class="rec">37.2%</div>
                    <div class="txt1">综合准确率</div>
                </div>
                <div class="right">
                    <div class="txt2">再巩固其中薄弱部分 <van-icon name="arrow" /></div>
                    <div class="txt3">
                        重新学习正确率较低的知识点 <br>
                        系统将更换新题目以便自测
                    </div>
                </div>
            </div>
            <div class="list2"" @click="eventList({lunshu:0,nandu:1})">
                <div class="left">
                    <img src="@/assets/new_img/img13.png" alt="" srcset="">
                </div>
                <div class="right">
                    <div class="txt2">进阶到综合提高 <van-icon name="arrow" /></div>
                    <div class="txt3">
                        精简题量，快速强化，高额奖励
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="reset" @click="zsd_del">重置全部进度</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                status: !false
            }
        },
        methods: {
            zsd_del() {
                this.$api.zsd_del({}).then(res=>{
                    console.log(res);
                    this.$toast.success('重置成功！')
                    this.$router.push('/new_topic')
                })
            },
            eventList(param){
                this.$api.zsd_trace_write({param}).then(res=>{
                    this.$router.push('/new_topic')
                })
            }
        },
    }
</script>

<style lang="less" scoped>
    .done{
        position: relative;
        height: calc(100vh - 70px);
        background: #F7F8FB;
        &-top{
            display: flex;
            justify-content: center;
            padding-top: 16px;
            img{
                width: 290px;
                height: 205px;
            }
        }
        .center{
            text-align: center;
            .p1{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height */
                text-align: center;
                color: #9498AA;
            }
            .p2{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height */
                text-align: center;
                color: #FF8282;
                padding-top: 5px;
            }
            .p3{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height */
                text-align: center;
                color: #415FD5;
                padding-top: 16px;
            }
        }
        .list1{
            padding: 16px;
            display: flex;
            background: #fff;
            width: 327px;
            margin: 0 24px;
            box-sizing: border-box;
            .left{
                .rec{
                    width: 56px;
                    height: 56px;
                    border-radius: 30px;
                    line-height: 56px;
                    text-align: center;
                    background: rgba(65,95,213, 0.06);

                    font-family: 'DIN Alternate';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    /* identical to box height */
                    text-align: center;
                    color: #415FD5;
                }
                .txt1{
                    padding-top: 5px;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 14px;
                    /* identical to box height */
                    color: rgba(148, 152, 170, 0.7);
                }
            }
            .right{
                width: 185px;
                padding-left: 15px;
                padding-top: 3px;
                text-align: left;
                .txt2{
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                    color: #2A3155;
                    display: flex;
                    align-items: center;
                }
                .txt3{
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #9498AA;
                    padding-top: 5px;
                }
            }
        }
        .list2{
            padding: 16px;
            display: flex;
            background: #fff;
            width: 327px;
            margin: 0 24px;
            box-sizing: border-box;
            margin-top: 16px;
            .left{
                img{
                    width: 56px;
                    height: 56px;
                }
            }
            .right{
                width: 215px;
                padding-left: 15px;
                padding-top: 3px;
                text-align: left;
                .txt2{
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                    color: #2A3155;
                    display: flex;
                    align-items: center;
                }
                .txt3{
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #9498AA;
                    padding-top: 5px;
                }
            }
        }
        .reset{
            position: absolute;
            width: 100%;
            text-align: center;
            bottom: 25px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height */
            text-align: center;
            color: #9498AA;
        }
    }
</style>